import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import "./App.css"
import ReactDOM from 'react-dom/client';
const rootEl = document.getElementById('app-site');
const MainApp = require('./MainApp').default;

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
  console.info = () => {};
}
if (process.env.NODE_ENV === 'development') {
  console.error = () => {};
  console.warn = () => {};
  console.info = () => {};
}
const root = ReactDOM.createRoot(document.getElementById('app-site'));
root.render(
<BrowserRouter>
  <MainApp/>
</BrowserRouter>,
rootEl
);
