import React from "react";
import axios from "axios";
import { API_URL, API_VERSION, API_TOKEN } from "../ApiUrl";

class Axios {
  defaultOptions = () => {
    return {
      baseURL: `${API_URL}${API_VERSION}`,
      headers: {
        token: localStorage.getItem("token"),
        adminid: localStorage.getItem("_id"),
      },
    };
  };

  LogoutUser = (url) => {
    console.log(url, 6666);
    if (url.response !== undefined && url.response.status === 401) {
      console.log(url.response.status, "401 Unauthorized Api Call");
      // localStorage.clear();
      // window.location.replace("/");
    } else {
      console.log(url, "401 Unauthorized Api Call");
    }
  };

  successResult = (res) => {
    if (res.data.message && res.data.message.includes("Not Authorized")) {
      let err = {
        response: {
          status: 401,
        },
      };
      this.LogoutUser(err);
    } else {
      return res;
    }
  };

  failureResult = (err) => {
    try {
      if (err.response.status === 401) {
        this.LogoutUser(err);
        return err;
      } else {
        return err;
      }
    } catch (error) {
      return err;
    }
  };
  get = (url, options = {}) => {
    return axios
      .get(url, { ...this.defaultOptions(), ...options })
      .then((res) => {
        return this.successResult(res);
      })
      .catch((err) => {
        return this.failureResult(err);
      });
  };

  post = (url, data, options = {}) => {
    return axios
      .post(url, data, { ...this.defaultOptions(), ...options })
      .then((res) => {
        return this.successResult(res);
      })
      .catch((err) => {
        return this.failureResult(err);
      });
  };

  put = (url, data, options = {}) => {
    return axios
      .put(url, data, { ...this.defaultOptions(), ...options })
      .then((res) => {
        return this.successResult(res);
      })
      .catch((err) => {
        return this.failureResult(err);
      });
  };

  delete = (url, options = {}) => {
    return axios
      .delete(url, { ...this.defaultOptions(), ...options })
      .then((res) => {
        return this.successResult(res);
      })
      .catch((err) => {
        return this.failureResult(err);
      });
  };
}

export default new Axios();
