import React from "react";
import { Redirect, Route, Switch, asyncComponent } from "../../../components";
const Customers = ({ match }) => {
  console.log(match, 65655);
  return (
    <div className="app-wrapper">
      <Switch>
        <Route
          path={`/app/earnings/:type/:editId`}
          component={asyncComponent(() => import("./routes/list"))}
        />
        <Route
          path={`/app/earnings/:type/:editId/view/:editId`}
          component={asyncComponent(() => import("./routes/view"))}
        />
        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
    </div>
  );
};

export default Customers;
