import React from "react";
import { withRouter } from "react-router-dom";

import { withProps, compose, lifecycle } from "recompose";
import {
  Axios,
  ApiUrl,
  Grid,
  Row,
  Col,
  CardText,
  CardBody,
  Card,
  Avatar,
  IntlMessages,
  Paper,
  DialogBox,
  Loading,
  moment,
  Status,
} from "../components";
import L from "leaflet";
import { Map as LeafletMap, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const { API_SHARE_LOCATION } = ApiUrl;
const google = window.google;
const API_KEY = '84HdhUJ65tw8qZKwSzjeVgompvwhJgP50m2fcVpvIoQ'

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});


class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tripId: "",
      data: {},
      startLocation: [],
      endLocation: [],
      loading: false,
    };
  }
  componentDidMount = () => {
    const {match} = this.props
    const tripId = match.params.id;
    this.setState({ loading: true });
    Axios.get(API_SHARE_LOCATION + tripId)
      .then((res) => {
        const { status, data } = res.data;
        if (status === "success") {
          const { startLocation, endLocation, driverLocation } = data;
          this.setState(
            {
              startLocation: startLocation,
              endLocation: endLocation,
              currentLocation: driverLocation,
              data: data,
            },
            () => {
              this.setState({ loading: false });
            }
          );
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  render() {
    const { loading, data } = this.state;
    return (
        <React.Fragment>
          <div id="map-container">
            <Row style={{ width: "100%", height: "100%" }}>
              <Col md={6} lg={6}>
                {(typeof data.driverLocation !== "undefined") ?  <MapBoxGL  detailss={data} /> : '' }
              </Col>
              <Col md={6} lg={6}>
                <TripDetails details={data} />
              </Col>
            </Row>
          </div>
        </React.Fragment>
      );
  }
}

export default withRouter(Map);

const MapBoxGL = (props) => {
    const { detailss } = props;
    return (
      <LeafletMap center={[detailss.driverLocation.coordinates[1], detailss.driverLocation.coordinates[0]]} zoom={12}>
          <TileLayer
            url={`https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${API_KEY}`}
            attribution={'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'}
          />
          <Marker position={[detailss.driverLocation.coordinates[1], detailss.driverLocation.coordinates[0]]} />
      </LeafletMap>
    );
};

const TripDetails = (props) => {
  const { details } = props;
  const { driverRefId } = details;
  let image = ["", null, undefined, "none"];
  return (
    <Grid container id='ride-share'>
      <Grid item xs={12}>
        <Paper>
          <Card className="details Driver_profile ride-share">
            <CardBody className="CcardPos row text-left col-sm-12">
              <h3 className="card-title col-sm-12">
                <IntlMessages id="trip.view" />
              </h3>
              <CardText className="col-sm-6">
                <IntlMessages id="field.startLocation" />:{" "}
                <span>{details.startLocationAddr}</span>
              </CardText>
              <CardText className="col-sm-6">
                <IntlMessages id="field.endLocation" />:{" "}
                <span>{details.endLocationAddr}</span>
              </CardText>
              <CardText className="col-sm-6">
                <IntlMessages id="field.distance" />:{" "}
                <span>
                  {details.distance} ( <IntlMessages id="common.miles" />)
                </span>
              </CardText>
              <CardText className="col-sm-6">
                <IntlMessages id="field.cost" />:
                <span>
                  {" "}
                  KSA {details.cost ? details.cost.toFixed(2) : "0.00"}
                </span>
              </CardText>
              <CardText className="col-sm-6">
                <IntlMessages id="field.estimatedTime" />:{" "}
                <span>
                  {details.estimatedTime} <IntlMessages id="common.minutes" />
                </span>
              </CardText>
              <CardText className="col-sm-6">
                <IntlMessages id="field.percentCharge" />:{" "}
                <span>{details.percentCharge}</span>
              </CardText>
              <CardText className="col-sm-6">
                <IntlMessages id="field.promocode" />:{" "}
                <span>
                  {!["none", "null", null, ""].includes(details.promoCode)
                    ? details.promoCode
                    : "N/A"}
                </span>
              </CardText>
              <CardText className="col-sm-6">
                <IntlMessages id="field.promocodeCost" />
                :KSA <span>{details.promoCodeCost} </span>
              </CardText>
              <CardText className="col-sm-6">
                <IntlMessages id="field.status" />:{" "}
                <span className={`label ${details.tripStatus}`}>
                  {Status[details.tripStatus]}
                </span>
              </CardText>
              <CardText className="col-sm-6">
                <IntlMessages id="field.tripCreatedAt" />:{" "}
                <span>
                  {moment(details.tripCreatedAt).format("DD/MM/YY HH:MM:SS A")}
                </span>
              </CardText>
            </CardBody>
          </Card>
          <Card className="details Driver_profile">
            <CardBody className="CcardPos row text-left col-sm-12">
              <h3 className="card-title col-sm-12">
                <IntlMessages id="customer.view" />
              </h3>
              <CardText className="col-sm-6">
                <IntlMessages id="field.name" />:{" "}
                <span>{details.customerName}</span>
              </CardText>
              <CardText className="col-sm-6">
                <IntlMessages id="field.mobile" />:{" "}
                <span>{details.customerNumber}</span>
              </CardText>
              <CardText className="col-sm-6">
                <IntlMessages id="field.rating" />:{" "}
                <span>{details.review && details.review.customerRating}</span>
              </CardText>
            </CardBody>
          </Card>
       
        </Paper>
      </Grid>
    

      {details.driverRefId && (
        <Grid item xs={12}>
          <Paper className={"classes.paper"}>
            <Card className="details Driver_profile">
              <CardBody className="CcardPos row text-left col-sm-12">
                <h3 className="card-title col-sm-12">
                  <IntlMessages id="driver.view" />
                </h3>
                <CardText className="col-sm-3">
                  <IntlMessages id="field.name" />:{" "}
                  <span>{driverRefId.name}</span>
                </CardText>
                <CardText className="col-sm-3">
                  <IntlMessages id="field.mobile" />:{" "}
                  <span>{driverRefId.mobileNumber}</span>
                </CardText>
                <CardText className="col-sm-3">
                  <IntlMessages id="field.email" />:{" "}
                  <span>{driverRefId.email}</span>
                </CardText>
                <CardText className="col-sm-3">
                  <IntlMessages id="field.rating" />:{" "}
                  <span>{details.review && details.review.driverRating}</span>
                </CardText>
              </CardBody>
            </Card>
          </Paper>
        </Grid>
      )}
      {details.driverRefId && driverRefId.selectedCar !== undefined && (
        <Grid item xs={12}>
          <Paper className={"classes.paper"}>
            <Card className="details Driver_profile">
              <CardBody className="CcardPos row text-left col-sm-12">
                <h3 className="card-title col-sm-12">
                  <IntlMessages id="car.view" />
                </h3>
                <CardText className="col-sm-2">
                  <img
                    style={{ width: 60, height: 60 }}
                    src={
                      driverRefId.selectedCar.carImage &&
                      !image.includes(driverRefId.selectedCar.carImage)
                        ? driverRefId.selectedCar.carImage
                        : require("assets/images/no-image.png")
                    }
                  />
                </CardText>
                <CardText className="col-sm-2">
                  <IntlMessages id="field.name" />:{" "}
                  <span>
                    <br />
                    {driverRefId.selectedCar.carName}
                  </span>
                </CardText>
                <CardText className="col-sm-2">
                  <IntlMessages id="field.color" />:{" "}
                  <span>
                    <br />
                    {driverRefId.selectedCar.carColor}
                  </span>
                </CardText>
                <CardText className="col-sm-2">
                  <IntlMessages id="field.type" />:{" "}
                  <span>
                    <br />
                    {driverRefId.selectedCar.carType}
                  </span>
                </CardText>
                <CardText className="col-sm-2">
                  <IntlMessages id="field.platenumber" />:{" "}
                  <span>
                    <br />
                    {driverRefId.selectedCar.plateNumber}
                  </span>
                </CardText>
              </CardBody>
            </Card>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
